interface PopupDetailProps {
  campaignName: string
  details: string
  onClosePopupDetail: () => void
}

interface PopupStyle {
  textColor: string
  fontSize: string
}

export const PopupDetail = (prop: PopupDetailProps) => {
  const popupStyle: PopupStyle = {
    textColor: 'text-[#6C737F]',
    fontSize: 'text-sm',
  }

  return (
    <div className="w-full h-full bg-black bg-opacity-40 p-5 flex items-center justify-center">
      <div className="w-80 h-auto bg-white p-5 rounded-lg flex flex-col items-center justify-center gap-1 max-h-[580px]">
        <h1 className="font-bold text-base">{prop.campaignName}</h1>
        <div
          className={`flex flex-col items-start justify-start w-full h-auto  overflow-y-auto [&_ul]:list-disc [&_ul]:pl-6 [&_ol]:list-decimal [&_ol]:pl-6  ${popupStyle.textColor}  ${popupStyle.fontSize}`}
          style={{
            scrollbarColor: '#E5E7EB transparent',
          }}
          dangerouslySetInnerHTML={{ __html: prop.details }}
        ></div>
        <div className="mt-3">
          <button className="bg-[#1E1E1E] text-white font-bold py-2 px-28 rounded" onClick={prop.onClosePopupDetail}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
