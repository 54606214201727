import fareBackground from '../assets/leaderboard/fare.svg'
import { LeaderboardHeaderData } from '../page/campaign-leaderboard'
import { BannerLeaderBoard } from './BannerLeaderBoard'
import { MoreInfoButton } from './MoreInfoButton'
import { ProgressComponent } from './ProgressComponent'

interface LeaderBoardHeaderProps {
  leaderboardHeaderData: LeaderboardHeaderData
  onOpenPopupDetail: () => void
}

export const LeaderBoardHeader = (props: LeaderBoardHeaderProps) => {
  return (
    <div
      className="w-full h-auto relative flex flex-col mt-3 
                items-center justify-start gap-2"
    >
      <img src={fareBackground} alt="fare" className="absolute w-full h-full mt-10" />
      <div className="w-full h-[110px] flex items-end justify-center mx-auto px-5 relative">
        <BannerLeaderBoard leaderboardHeaderData={props.leaderboardHeaderData} />
      </div>
      <div className="w-full h-auto flex flex-col items-center justify-start text-center relative">
        <div className="flex-1 px-5 max-w-[385px] h-auto flex flex-col items-center justify-start text-center">
          <p className="text-[#DCF13B] text-lg font-semibold">{props.leaderboardHeaderData.campaignName}</p>
          {props.leaderboardHeaderData.isShowProgressBar && (
            <ProgressComponent
              currentValue={props.leaderboardHeaderData.voteCurrentValue}
              targetProgressValue={props.leaderboardHeaderData.voteTargetValue}
            />
          )}
          {props.leaderboardHeaderData.descriptionAnnounce && (
            <p className="text-white text-xs pt-1.5 ">{props.leaderboardHeaderData.descriptionAnnounce}</p>
          )}
          <MoreInfoButton onOpenPopupDetail={props.onOpenPopupDetail} />
        </div>
      </div>
    </div>
  )
}
