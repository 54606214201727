import info from '../assets/leaderboard/info.svg'

interface MoreInfoButtonProps {
  onOpenPopupDetail: () => void
}

export const MoreInfoButton = (props: MoreInfoButtonProps) => {
  return (
    <div className="flex flex-row gap-0.5 py-1 ">
      <img className="" src={info} alt="" />
      <button className="text-white text-xs rounded underline" onClick={props.onOpenPopupDetail}>
        more info
      </button>
    </div>
  )
}
