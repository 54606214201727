interface Coupon {
  success: boolean
}

export enum CodeStatus {
  NOT_FOUND = '60011',
  EXPIRED = '60012',
  USED = '60013',
  NO_REWARD = '60015',
  REWARD = 'REWARD',
  ERROR = 'ERROR',
}

export type { Coupon }
