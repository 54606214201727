import React, { useState } from 'react'
import { LeaderboardHeaderData } from '../page/campaign-leaderboard'

interface BannerLeaderBoardProps {
  leaderboardHeaderData: LeaderboardHeaderData
}

export const BannerLeaderBoard = (props: BannerLeaderBoardProps) => {
  const [isLoading, setIsLoading] = useState(true)

  if (!props.leaderboardHeaderData.bannerFile) {
    return <DefaultBannerComponent isPlayAnimatePulse={false} />
  }

  return (
    <>
      {isLoading && <DefaultBannerComponent isPlayAnimatePulse={true} />}
      <a className="h-full" href={`${props.leaderboardHeaderData.redirectLink}`} target="_blank" rel="noreferrer">
        <img
          src={props.leaderboardHeaderData.bannerFile}
          alt=""
          className={`w-[353px] h-full object-cover rounded-md bg-[#ffffff] ${
            isLoading ? 'opacity-0' : 'opacity-100'
          } `}
          onLoad={() => setIsLoading(false)}
        />
      </a>
    </>
  )
}

interface DefaultBannerComponentProps {
  isPlayAnimatePulse: boolean
}

const DefaultBannerComponent = (props: DefaultBannerComponentProps) => {
  return (
    <div
      className={`w-[353px] h-full object-cover rounded-md bg-[#ffffff] ${
        props.isPlayAnimatePulse && `animate-pulse absolute`
      }`}
    />
  )
}
