import rankingBlueIcon from '../assets/leaderboard/ranking-blue-icon.svg'

interface Props {
  noRanking: number
}

export const RankingIcon = (props: Props) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <p className="absolute text-center pb-3 font-extrabold text-sm text-[#0A79EB]">{props.noRanking}</p>
      <img className="p-1 min-w-9" src={rankingBlueIcon} alt="" />
    </div>
  )
}
