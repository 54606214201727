import firstIcon from '../assets/leaderboard/first-icon.svg'
import secondIcon from '../assets/leaderboard/second-icon.svg'
import thirdIcon from '../assets/leaderboard/third-icon.svg'
import voteIcon from '../assets/leaderboard/vote-icon.svg'
import token from '../assets/leaderboard/token.svg'
import { ProfileLeaderBoard } from './ProfileLeaderBoard'
import { RankingIcon } from './RankingIcon'
import { TopSpenderData, TopSpenderDataType } from '../page/campaign-leaderboard'

interface LeaderBoardItemProps {
  ranking: number
  topSpenderData: TopSpenderData
  numberTopSpenderReward: number
  numberTopSpenderRanking: number
  topSpenderDataType: TopSpenderDataType
  isShowType: boolean
}

interface LeaderBoardItemStyle {
  rankingIcon: string
  backgroundColor: string
  profileBorderColor: string
  userNameAndBathColor: string
  totalSpentCoinColor: string
}

export const LeaderBoardItem = (props: LeaderBoardItemProps) => {
  const rankingStyle: LeaderBoardItemStyle[] = [
    {
      rankingIcon: firstIcon,
      backgroundColor: 'bg-[#FFCA3C]',
      profileBorderColor: 'border-[#FFE08E]',
      userNameAndBathColor: 'text-[#E2574C]',
      totalSpentCoinColor: 'text-[#9D2717]',
    },
    {
      rankingIcon: secondIcon,
      backgroundColor: 'bg-[#CFCFCF]',
      profileBorderColor: 'border-[#F3F4F6]',
      userNameAndBathColor: 'text-[#538610]',
      totalSpentCoinColor: 'text-[#375A0A]',
    },
    {
      rankingIcon: thirdIcon,
      backgroundColor: 'bg-[#FFA77F]',
      profileBorderColor: 'border-[#FFE1D3]',
      userNameAndBathColor: 'text-[#D72751]',
      totalSpentCoinColor: 'text-[#9D0026]',
    },
    {
      rankingIcon: '',
      backgroundColor: 'bg-[#D7F2FF]',
      profileBorderColor: 'border-[#88DFFF]',
      userNameAndBathColor: 'text-[#0A79EB]',
      totalSpentCoinColor: 'text-[#0F60BE]',
    },
  ]

  const isRankingLessThanEqualLimitReward = props.ranking <= props.numberTopSpenderReward

  const defaultRankingClassStyle = {
    rankingIcon: '',
    backgroundColor: 'bg-[#FFFFFF]',
    profileBorderColor: 'border-[#C7C7CC]',
    userNameAndBathColor: 'text-[#6C737F]',
    totalSpentCoinColor: 'text-[#1E1E1E]',
  }

  const rankingClassStyle = isRankingLessThanEqualLimitReward
    ? rankingStyle[props.ranking - 1] || rankingStyle.at(-1)
    : defaultRankingClassStyle

  const rankingIcon = isRankingLessThanEqualLimitReward ? (
    rankingClassStyle.rankingIcon ? (
      <img className="p-1 min-w-9" src={rankingClassStyle.rankingIcon} alt="" />
    ) : (
      <RankingIcon noRanking={props.ranking} />
    )
  ) : (
    <div
      className="w-9 h-9 min-w-9
                 bg-gray-400 rounded-full 
                 flex items-center justify-center
                 text-white font-semibold text-base"
    >
      {props.ranking}
    </div>
  )

  const profileImage = props.ranking <= props.numberTopSpenderReward && (
    <ProfileLeaderBoard
      profile_image={props.topSpenderData.profile_image}
      profileBorderColor={rankingClassStyle.profileBorderColor}
    />
  )

  return (
    <div>
      <div
        className={`rounded-2xl max-h-16 ${rankingClassStyle.backgroundColor} shadow-sm p-2.5 
                    rounded-lg flex items-center gap-2`}
      >
        {rankingIcon}
        {profileImage}
        <div className="w-full h-12 flex flex-row items-start justify-between">
          <div className="w-0 h-full flex-1 flex flex-col justify-center">
            <p className={`${rankingClassStyle.userNameAndBathColor} font-semibold text-base truncate`}>
              {props.topSpenderData.display_name || '-'}
            </p>
          </div>
          {/* // TODO : refactor this to component*/}
          {props.isShowType ? (
            props.topSpenderDataType === TopSpenderDataType.Coin ? (
              <div className="w-24 h-full flex flex-col items-end justify-evenly">
                <div className="flex flex-row gap-0.5">
                  <img className="w-5 h-5 py-0.5" src={token} alt="" />
                  <p className={`${rankingClassStyle.totalSpentCoinColor} font-semibold text-base pr-0.5`}>
                    {props.topSpenderData.total_spent || 0}
                  </p>
                </div>
                <p className={`${rankingClassStyle.userNameAndBathColor} text-xs`}>
                  ({props.topSpenderData.total_spent * 0.5 || 0} ฿)
                </p>
              </div>
            ) : (
              <div className="w-28 h-full flex flex-row items-center justify-end gap-1">
                <img className="w-5 h-6" src={voteIcon} alt="" />
                <p className={`${rankingClassStyle.totalSpentCoinColor} text-sm font-bold text-right`}>
                  {props.topSpenderData.total_spent || 0}
                </p>
                <p className={`${rankingClassStyle.totalSpentCoinColor} text-xs font-bold text-right`}>vote</p>
              </div>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
