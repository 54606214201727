import axios, { Axios } from 'axios'
import { Coupon } from '../model/redeem'
class RedeemClient {
  private readonly axiosInstance: Axios

  constructor(url?: string) {
    this.axiosInstance = axios.create({
      baseURL: url,
    })
  }

  async checkCodeRedeem(code: string): Promise<Coupon | null> {
    try {
      const resp = await this.axiosInstance.post(`/api/personalize/v1/public/coupon-codes/${code}/validate`)
      return resp.data
    } catch (error) {
      console.error('Error during code redemption:', error)
      return Promise.reject(error)
    }
  }
}

export default RedeemClient
