/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Loading from '../component/Loading'
import createCardSuccessImg from '../assets/credit-card-success.png'
import useAuthStore from '../store/authStore'
import { isAndroid, isIOS, isMobile } from '../utils/device'

import usePaymentTransactionStore from '../store/paymentTransactionStore'
import { PaymentType, TransactionStatus } from '../model/paymentTransaction'

const PaymentCompletePage = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const txId = queryParams.get('txId')
  const uri = process.env.REACT_APP_BONDBOND_APP_DEEPLINK_URL || 'treeroots://bondbond.com'
  const navigate = useNavigate()
  const user = useAuthStore((state) => state.user)
  const getTransaction = usePaymentTransactionStore((state) => state.getPaymentTransaction)
  const transaction = usePaymentTransactionStore((state) => state.transaction)
  const txLoading = usePaymentTransactionStore((state) => state.loading)
  const isPendingQrPayment =
    transaction?.paymentType === PaymentType.PromptPay && transaction.status === TransactionStatus.pending
  const isPaymentFailed = transaction?.status === TransactionStatus.failed

  useEffect(() => {
    if (!user) {
      if (isAndroid || isIOS) {
        window.location.href = `${uri}/home`
      } else {
        console.log('Not an iOS or Android device.')
      }
    } else {
      if (txId) {
        getTransaction(txId)
      }
    }
  }, [])

  useEffect(() => {
    if (isPendingQrPayment) {
      navigate(`/payment/qr?txId=${txId}`)
    } else if (isPaymentFailed) {
      navigate(`/payment/failed?txId=${txId}`)
    }
  }, [transaction])

  return (
    <div className="flex flex-col items-center justify-center">
      {!!user && !txLoading ? (
        <>
          <div className="flex flex-col items-center bg-white rounded-lg shadow-s mt-8 py-8" style={{ maxWidth: 592 }}>
            <div className="flex flex-col items-center px-16">
              <div className="flex justify-center mb-5">
                <img src={createCardSuccessImg} alt="payment success" className="w-20 h-20" />
              </div>
              <h2 className="text-gray-800 font-bold text-lg mb-1">Top-up successful</h2>
              <p className="text-gray-500 text-center text-base">
                {'You can check the amount of coins and top-up details in\nthe bondbond application.'}
              </p>
            </div>
            <div className="mt-6 text-base">
              <span className="text-gray-500">Order ID</span>
              <span className="ml-2 text-gray-800 font-bold">{transaction?.metadata?.orderId}</span>
            </div>
            <div className="w-full px-16">
              {!isMobile && (
                <button
                  onClick={() => navigate('/packages')}
                  className="w-full rounded py-2.5 px-3.5 mt-6 bg-nightBlack shadow-xs text-white text-base font-bold"
                >
                  Back to package page
                </button>
              )}
              {isMobile && (
                <button
                  onClick={() => {
                    window.location.href = `${uri}/home`
                  }}
                  className="w-full rounded py-2.5 px-3.5 mt-6 bg-nightBlack shadow-xs text-white text-base font-bold"
                >
                  Open App
                </button>
              )}
            </div>
          </div>
          {isMobile && (
            <Link to={'/packages'} className="mt-8 text-primary-700 text-base font-bold">
              Go to package page
            </Link>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default PaymentCompletePage
