interface ProgressBarProps {
  currentValue: number
  targetProgressValue: number
}

interface ProgressBarStyle {
  normalColor: string
  doneColor: string
}

export const ProgressComponent = (props: ProgressBarProps) => {
  return (
    <div className="w-[355px] h-[52px] bg-[#28AEFF] rounded-md pl-4 pr-2 flex flex-row justify-center mt-2">
      <div className="w-full flex flex-row justify-start items-center gap-2">
        <p className="pb-3 text-sm font-normal text-white ">Progress</p>
        <div className="w-full pt-2.5">
          <ProgressBar {...props} />
          <div className="w-62  flex flex-row justify-between items-start pt-1.5  ">
            <p className="text-[10px] font-normal text-[#D7F2FF]">0 vote</p>
            <p className="text-[10px] font-normal text-[#D7F2FF]">{props.targetProgressValue} vote</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const ProgressBar = (props: ProgressBarProps) => {
  const calculateProgressPercentage = (value: number, min: number, max: number) =>
    Math.min(Math.max(value, min), max) || 0

  const rawPercentage = (props.currentValue / props.targetProgressValue) * 100
  const percentage = calculateProgressPercentage(rawPercentage, 0, 100)

  const progressBarStyle: ProgressBarStyle = {
    normalColor: 'bg-[#FFAD39]',
    doneColor: 'bg-[#BBD60C]',
  }

  const barColor = percentage >= 100 ? progressBarStyle.doneColor : progressBarStyle.normalColor

  return (
    <div className="relative">
      <div
        className=" w-full bg-[#E5E7EB] rounded-full overflow-hidden"
        role="progressbar"
        aria-valuenow={percentage}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <div
          className={`${barColor} h-4 rounded-l-full flex items-start justify-end ${
            percentage > 18 && 'transition-all duration-300 ease-in-out'
          }`}
          style={{ width: `${percentage}%` }}
        >
          <div
            className="absolute inset-y-0 flex items-center opacity-100"
            style={{ left: `${percentage}%`, transform: `translateX(-${percentage}%)` }}
          >
            <div
              className={`h-[26px] w-[40px] rounded-md ${barColor} flex items-center justify-center font-medium text-white text-xs`}
            >
              {percentage.toFixed(0)}%
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
