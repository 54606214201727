import { LeaderBoardItem } from './LeaderBoardItem'
import trophyIcon from '../assets/leaderboard/trophy.svg'
import { AnnounceConfig, TopSpenderData, TopSpenderDataType } from '../page/campaign-leaderboard'

interface LeaderBoardListProps {
  topSpenderDataArray: TopSpenderData[]
  numberTopSpenderReward: number
  numberTopSpenderRanking: number
  announceConfigList: AnnounceConfig[]
}

function mapLeaderboardData(
  topSpenderData: TopSpenderData[],
  numberTopSpenderReward: number,
  numberTopSpenderRanking: number,
  isShow: boolean,
): TopSpenderData[] {
  const total: number = numberTopSpenderReward + numberTopSpenderRanking

  topSpenderData = isShow ? topSpenderData : []
  return [
    ...topSpenderData.slice(0, total).sort((a, b) => {
      if (b.total_spent !== a.total_spent) {
        return b.total_spent - a.total_spent
      }
      return new Date(a.date_created).getTime() - new Date(b.date_created).getTime()
    }),
    ...Array(Math.max(0, total - topSpenderData.length)).fill({
      display_name: undefined,
      full_name: undefined,
      profile_image: undefined,
      total_spent: undefined,
      rank: undefined,
      date_created: undefined,
    }),
  ]
}

function createLeaderboardItem(
  index: number,
  topSpenderData: TopSpenderData,
  numberTopSpenderReward: number,
  numberTopSpenderRanking: number,
  topSpenderDataType: TopSpenderDataType,
  isShowType: boolean,
) {
  if (index !== numberTopSpenderReward) {
    return (
      <LeaderBoardItem
        key={index}
        ranking={index + 1}
        topSpenderData={topSpenderData}
        numberTopSpenderReward={numberTopSpenderReward}
        numberTopSpenderRanking={numberTopSpenderRanking}
        topSpenderDataType={topSpenderDataType}
        isShowType={isShowType}
      />
    )
  } else {
    return (
      <div key={index} className="flex flex-col gap-2 pt-1.5">
        <div className="flex items-center pb-1.5">
          <div className="border-t border-2 border-white flex-grow"></div>
          <img className="pl-2 pr-2" src={trophyIcon} alt="" />
          <div className="border-t border-2 border-white flex-grow"></div>
        </div>
        <LeaderBoardItem
          ranking={index + 1}
          topSpenderData={topSpenderData}
          numberTopSpenderReward={numberTopSpenderReward}
          numberTopSpenderRanking={numberTopSpenderRanking}
          topSpenderDataType={topSpenderDataType}
          isShowType={isShowType}
        />
      </div>
    )
  }
}

export const LeaderBoardList = (props: LeaderBoardListProps) => {
  const propsFilter = props.announceConfigList
    .filter((x) => x.announceDate !== undefined)
    .sort((a, b) => b.announceDate?.getTime() - a?.announceDate?.getTime())
  const thisDayIsShow = propsFilter.find((x) => new Date().getTime() - x.announceDate.getTime() >= 0)
  return (
    <div
      className="w-full  max-w-[380px] flex-1 flex items-start justify-center 
                    mx-auto mt-2 pl-4 pr-4 overflow-y-auto overscroll-contain relative"
      style={{
        scrollbarColor: '#E5E7EB transparent',
      }}
    >
      <div className="w-full  pb-5 max-w-[353px] mx-auto flex flex-col gap-2">
        {mapLeaderboardData(
          props.topSpenderDataArray,
          props.numberTopSpenderReward,
          props.numberTopSpenderRanking,
          thisDayIsShow !== undefined,
        ).map((topSpenderData, index) =>
          createLeaderboardItem(
            index,
            topSpenderData,
            props.numberTopSpenderReward,
            props.numberTopSpenderRanking,
            thisDayIsShow?.topSpenderDataType || TopSpenderDataType.Vote,
            thisDayIsShow?.isShowType ?? false,
          ),
        )}
      </div>
    </div>
  )
}
