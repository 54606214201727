/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import Loading from '../component/Loading'
import { useNavigate } from 'react-router-dom'
import { formatTimeToMMSS, getNowTimestamp } from '../utils/time'
import usePaymentTransactionStore from '../store/paymentTransactionStore'
import { TransactionStatus } from '../model/paymentTransaction'
import Button from '../component/Button'

const COUNTDOWN_END_TIME = 'countdownEndTime'

const PaymentQrPage = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const txId = queryParams.get('txId')
  const navigate = useNavigate()
  const transaction = usePaymentTransactionStore((state) => state.transaction)
  const txLoading = usePaymentTransactionStore((state) => state.loading)
  const getTransaction = usePaymentTransactionStore((state) => state.getPaymentTransaction)
  const downloadQrCode = usePaymentTransactionStore((state) => state.downloadQrCode)
  const downloading = usePaymentTransactionStore((state) => state.downloading)
  const qrCodeRef = useRef<HTMLImageElement | null>(null)
  const [timeLeft, setTimeLeft] = useState(0)

  // useEffect for countdown timer
  useEffect(() => {
    const savedEndTime = localStorage.getItem(`${COUNTDOWN_END_TIME}_${txId}`)
    const endTime = savedEndTime ? parseInt(savedEndTime, 10) : Date.now() + 10 * 60 * 1000
    if (!savedEndTime) {
      localStorage.setItem(`${COUNTDOWN_END_TIME}_${txId}`, `${endTime}`)
    }

    const updateCountdown = () => {
      const remainingTime = Math.max(0, endTime - Date.now())
      setTimeLeft(remainingTime)

      if (remainingTime === 0) {
        navigate(`/payment/failed?txId=${txId}`)
        localStorage.removeItem(`${COUNTDOWN_END_TIME}_${txId}`) // Clear localStorage when time is up
        clearInterval(interval)
      }
    }

    // Update countdown every second
    const interval = setInterval(updateCountdown, 1000)
    updateCountdown() // Run immediately to avoid delay

    return () => {
      clearInterval(interval)
      localStorage.removeItem(`${COUNTDOWN_END_TIME}_${txId}`)
    }
  }, [])

  // useEffect for polling check transaction status
  useEffect(() => {
    if (!txId) return

    const pollingInterval = startPolling(txId)
    return () => {
      clearInterval(pollingInterval)
    }
  }, [])

  // Function to trigger download of QR Code
  const saveQRCode = async () => {
    if (!txId) return
    const response = await downloadQrCode(txId)
    if (!response) return
    const href = URL.createObjectURL(response)

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href
    link.target = "_blank";
    link.setAttribute('download', `QRCode_${getNowTimestamp()}.png`) //or any other extension
    link.click()

    link?.parentNode?.removeChild(link);

    URL.revokeObjectURL(href)
  }

  // Function to start polling for transaction status
  const startPolling = (transactionId: string) => {
    const interval = setInterval(async () => {
      const fetchedTx = await getTransaction(transactionId)

      if (fetchedTx?.status === TransactionStatus.successful) {
        clearInterval(interval)
        navigate(`/payment/complete?txId=${txId}`) // Redirect to the payment completed page
      } else if (fetchedTx?.status === TransactionStatus.failed) {
        clearInterval(interval)
        navigate(`/payment/failed?txId=${txId}`)
      }
    }, 5000) // Poll every 5 seconds
    return interval
  }

  return (
    <div className="flex justify-center">
      {!txLoading ? (
        <div
          className="flex flex-col w-full items-center bg-white rounded-lg shadow-s mt-8 py-8 px-16"
          style={{ maxWidth: 592 }}
        >
          <h2 className="text-gray-800 font-bold text-lg mb-2">Pay with PromptPay</h2>
          <p className="text-gray-800 font-bold text-base mb-2">{'Please pay within ' + formatTimeToMMSS(timeLeft)}</p>
          <div className="flex justify-center mb-3">
            <img
              ref={qrCodeRef}
              src={transaction?.metadata?.qrCode?.image?.downloadUri}
              alt="qr code"
              className="w-60"
            />
          </div>
          <p className="text-gray-800 font-bold text-lg mb-2">{`Amount: ${transaction?.displayAmount} ${transaction?.currency ?? ''
            }`}</p>
          <p className="text-gray-500 text-center text-sm mb-2">{`Ref no: ${transaction?.providerRefId}`}</p>
          <div className="mt-2 text-sm">
            <p className="text-gray-800 font-bold">Condition</p>
            <p className="text-gray-500 whitespace-pre-line">{`1. Please complete the payment within 10 minutes before the QR code expires.
                            2. Please check your wallet balance after the payment is successful. if it isn't updated, please contact our customer service.`}</p>
          </div>
          <Button
            loading={downloading}
            onClick={saveQRCode}
            className="md:w-full mt-6"
          >
            Save QR code
          </Button>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default PaymentQrPage
