import { useState } from 'react'
import profileIcon from '../assets/leaderboard/default-profile.svg'

interface ProfileLeaderBoardProps {
  profile_image: string
  profileBorderColor: string
}

export const ProfileLeaderBoard = (props: ProfileLeaderBoardProps) => {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <div>
      {isLoading && (
        <div
          className={`w-12 h-12 
            min-w-12 bg-gray-100
            ${props.profileBorderColor} 
            rounded-full animate-pulse border-[2px] absolute`}
        />
      )}
      <img
        src={props.profile_image || profileIcon}
        alt=""
        className={`w-12 h-12 
        min-w-12 bg-gray-100
        ${props.profileBorderColor} 
        border-[2px] rounded-full ${isLoading ? 'opacity-0' : 'opacity-100'}`}
        onLoad={() => setIsLoading(false)}
      />
    </div>
  )
}
