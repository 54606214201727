import { useEffect, useState } from 'react'
import Button from '../component/Button'
import ashEchoesLogo from '../assets/ash-echoes-logo.png'
import bondbondLogo from '../assets/bondbond-logo.svg'
import congratulation from '../assets/congratulation.svg'
import contentCopy from '../assets/content-copy.svg'
import { Turnstile } from '@marsidev/react-turnstile'
import RedeemClient from '../services/redeem'
import { Link, useParams } from 'react-router-dom'
import { CodeStatus } from '../model/redeem'
import bondbondAppLogo from '../assets/bondbond-app-logo.svg'

const url = process.env.REACT_APP_API_GATEWAY_URL
const turnStileKey = process.env.REACT_APP_TURNSTILE_SITE_KEY
const urlAppBondBond = process.env.REACT_APP_URL_APP_BONDBOND || ''
const urlReadMore = process.env.REACT_APP_URL_READ_MORE || ''
const urlFooterHere = process.env.REACT_APP_URL_FOOTER_HERE || ''

// NOTE: if make test e2e fix env REACT_APP_IS_MOCK_TEST to true
const isMockTest = Boolean(process.env.REACT_APP_IS_MOCK_TEST) || false

const client = new RedeemClient(url)

interface AppBarFooterProps {
  codeStatus: CodeStatus
}

const getBackgroundColor = (codeStatus: CodeStatus) =>
  codeStatus === CodeStatus.REWARD ? 'bg-[#FE6E7E]' : 'bg-[#9DA4AE]'

const AppBar: React.FC<AppBarFooterProps> = ({ codeStatus }) => (
  <div
    className={`fixed top-0 left-0 right-0 z-50 ${getBackgroundColor(
      codeStatus,
    )} backdrop-blur-md border-b border-gray-200`}
  >
    <div className="flex items-center h-14 px-4">
      <div className="absolute left-1/2 -translate-x-1/2 font-semibold text-white flex items-center gap-2">
        <img src={ashEchoesLogo} alt="ash-echoes-logo" className="w-[84px] h-[18.16px]" />
        <div className="text-white text-xl font-bold">x</div>
        <img src={bondbondLogo} alt="bondbond-logo" />
      </div>
    </div>
  </div>
)

const Footer: React.FC<AppBarFooterProps> = ({ codeStatus }) => (
  <div className={`fixed bottom-0 left-0 right-0 ${getBackgroundColor(codeStatus)} pt-4 pb-8 px-4`}>
    <div className="max-w-md w-full flex items-center justify-center gap-4 mx-auto">
      <img src={bondbondAppLogo} alt="bondbond-app-logo" className="w-[64px] h-[64px]" />
      <div>
        <div className="text-[#ffffff] text-lg font-bold font-['LINE Seed Sans TH'] leading-7">
          Don't have bondbond app?
        </div>
        <div>
          <span className="text-[#ffffff] text-sm font-normal font-['LINE Seed Sans TH'] leading-tight">
            You can download bondbond app{' '}
          </span>
          <Link
            to={urlFooterHere}
            target="_blank"
            className="text-[#ffffff] text-sm font-bold font-['LINE Seed Sans TH'] underline leading-tight"
          >
            here!
          </Link>
        </div>
      </div>
    </div>
  </div>
)

const ContentWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="pt-14 pb-32 p-4 flex items-center justify-center min-h-screen">
    <div className="max-w-md w-full space-y-8 p-6">{children}</div>
  </div>
)

const Header: React.FC<{ title: string; showCongratulation?: boolean }> = ({ title, showCongratulation }) => (
  <div className="flex items-center justify-center gap-2 mb-8">
    <h1 className="text-center text-[#1e1e1e] text-3xl font-bold">{title}</h1>
    {showCongratulation && <img src={congratulation} alt="congratulation" />}
  </div>
)

const CodeInput: React.FC<{
  code: string
  canCopy?: boolean
  onCopyCode?: () => void
  copied?: boolean
  codeInvalid: boolean
}> = ({ code, canCopy, onCopyCode, copied, codeInvalid }) => (
  <div className="relative">
    <input
      type="text"
      className="block w-full p-2 text-[#6c737f] text-base font-bold border border-gray-300 rounded-lg bg-gray-50"
      value={codeInvalid ? 'invalid code' : code}
      disabled
    />
    {canCopy && (
      <>
        <img
          className="absolute end-1 bottom-1 text-sm px-1 py-1 cursor-pointer"
          src={contentCopy}
          alt="copy"
          onClick={onCopyCode}
        />
        {copied && <div className="absolute -top-8 right-0 bg-black text-white text-sm px-2 py-1 rounded">Copied!</div>}
      </>
    )}
  </div>
)

const Instructions: React.FC = () => (
  <div className="space-y-4">
    <h2 className="text-[#1f2a37] text-sm font-bold">How to use the code</h2>
    <ol className="text-[#6c737f] text-sm font-bold">
      <li>1. Copy the code and tap "bonding now!" button</li>
      <li>2. Select bonding message and fill in the details</li>
      <li>3. On "Summary page", paste the code in "Coupon Code" section</li>
    </ol>
    <Link
      to={urlReadMore}
      className="text-[#1594ff] text-sm font-bold font-['LINE Seed Sans TH'] underline leading-tight"
    >
      Read more
    </Link>
  </div>
)

const getContentConfig = ({ codeStatus }: { codeStatus: CodeStatus }) => {
  const commonMessage = 'Make a 300 baht purchase to get another code for a chance to redeem 1 free bonding message'

  switch (codeStatus) {
    case CodeStatus.EXPIRED:
      return {
        title: 'Campaign ended',
        cardTitle: '',
        cardMessage: '',
        showSubmitButton: false,
        showCongratulation: false,
        commonMessage: 'This campaign has already ended. Please stay tuned for the next campaign.',
        isRenderCodeCard: false,
        showInstructions: false,
        codeInvalid: false,
      }
    case CodeStatus.USED:
      return {
        title: 'This code has already been used',
        cardTitle: 'Your code',
        cardMessage: 'This code cannot be redeemed :(',
        showSubmitButton: false,
        showCongratulation: false,
        commonMessage: commonMessage,
        isRenderCodeCard: true,
        showInstructions: false,
        codeInvalid: false,
      }
    case CodeStatus.REWARD:
      return {
        title: 'Congratulations!',
        cardTitle: 'Your code',
        cardMessage: 'This code can be redeemed for 1 free bonding message in bondbond app',
        showSubmitButton: true,
        showCongratulation: true,
        commonMessage: '',
        isRenderCodeCard: true,
        showInstructions: true,
        codeInvalid: false,
      }
    case CodeStatus.NOT_FOUND:
      return {
        title: "This code doesn't win the prize",
        cardTitle: 'Your code',
        cardMessage: 'This code cannot be redeemed :(',
        showSubmitButton: false,
        showCongratulation: false,
        commonMessage: commonMessage,
        isRenderCodeCard: true,
        showInstructions: false,
        codeInvalid: true,
      }
    case CodeStatus.NO_REWARD:
      return {
        title: "This code doesn't win the prize",
        cardTitle: 'Your code',
        cardMessage: 'This code cannot be redeemed :(',
        showSubmitButton: false,
        showCongratulation: false,
        commonMessage: commonMessage,
        isRenderCodeCard: true,
        showInstructions: false,
        codeInvalid: false,
      }
    case CodeStatus.ERROR:
    default:
      return {
        title: 'Oops! Something went wrong',
        cardTitle: '',
        cardMessage: '',
        showSubmitButton: false,
        showCongratulation: false,
        commonMessage: 'Please come back again later',
        isRenderCodeCard: false,
        showInstructions: false,
        codeInvalid: false,
      }
  }
}

const AshRedeemPage: React.FC = () => {
  const { couponCode } = useParams()
  const [code, setCode] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [copied, setCopied] = useState<boolean>(false)
  const [verified, setVerified] = useState(false)
  const [codeStatus, setCodeStatus] = useState<CodeStatus>(CodeStatus.ERROR)

  // FIXME: need refactor to client interface for inject mock later
  const fetchCodeMock = async (codeToCheck: string) => {
    switch (codeToCheck) {
      case 'REWARD':
        return setCodeStatus(CodeStatus.REWARD)
      case 'NO_REWARD':
        return setCodeStatus(CodeStatus.NO_REWARD)
      case 'USED':
        return setCodeStatus(CodeStatus.USED)
      case 'EXPIRED':
        return setCodeStatus(CodeStatus.EXPIRED)
      case 'NOT_FOUND':
        return setCodeStatus(CodeStatus.NOT_FOUND)
      default:
        return setCodeStatus(CodeStatus.ERROR)
    }
  }

  const fetchCode = async (codeToCheck: string) => {
    try {
      const result = await client.checkCodeRedeem(codeToCheck)
      if (result?.success) {
        return setCodeStatus(CodeStatus.REWARD)
      }
    } catch (error: any) {
      if ([404, 400].includes(error.response?.status)) {
        return setCodeStatus(error.response?.data.code)
      }
      return setCodeStatus(CodeStatus.ERROR)
    }
  }

  useEffect(() => {
    if (couponCode) {
      setCode(couponCode)
      if (isMockTest) {
        fetchCodeMock(couponCode)
      } else {
        fetchCode(couponCode)
      }
    }
  }, [couponCode])

  const onCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(code)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    await onCopyCode()
    window.open(urlAppBondBond, '_blank')
    setIsLoading(false)
  }

  const renderCodeCard = (title: string, message: string, showSubmitButton = false, codeInvalid = false) => (
    <div className="w-full max-w-md bg-white rounded-lg shadow-md overflow-hidden">
      <div className="p-6">
        <div className="text-[#1f2a37] text-lg font-bold text-center">{title}</div>
        <div className="text-center text-[#6c737f] text-sm font-normal mb-6">{message}</div>
        <form onSubmit={handleSubmit}>
          <CodeInput
            code={code}
            canCopy={showSubmitButton}
            onCopyCode={onCopyCode}
            copied={copied}
            codeInvalid={codeInvalid}
          />
          {showSubmitButton && (
            <Button type="submit" disabled={isLoading} aria-busy={isLoading} className="md:w-full mt-8">
              bonding now!
            </Button>
          )}
        </form>
      </div>
    </div>
  )

  const Content: React.FC<{ codeStatus: CodeStatus }> = ({ codeStatus }) => {
    const config = getContentConfig({ codeStatus: codeStatus })

    return (
      <ContentWrapper>
        <Header title={config.title} showCongratulation={config.showCongratulation} />
        {config.commonMessage !== '' && (
          <p className="text-center text-[#6c737f] text-sm font-normal mb-6">{config.commonMessage}</p>
        )}
        {config.isRenderCodeCard && (
          <>
            {renderCodeCard(config.cardTitle, config.cardMessage, config.showSubmitButton, config.codeInvalid)}
            {config.showInstructions && <Instructions />}
          </>
        )}
      </ContentWrapper>
    )
  }

  const bgGray = `min-h-screen flex items-center justify-center p-4 bg-[url('/src/assets/bg-gray-mobile.svg')] md:bg-[url('/src/assets/bg-gray-ipad.svg')] lg:bg-[url('/src/assets/bg-gray-desktop.svg')] bg-cover bg-center`
  const bgRose = `min-h-screen flex items-center justify-center p-4 bg-[url('/src/assets/bg-rose-mobile.svg')] md:bg-[url('/src/assets/bg-rose-ipad.svg')] lg:bg-[url('/src/assets/bg-rose-desktop.svg')] bg-cover bg-center`
  const bg = codeStatus === CodeStatus.REWARD ? bgRose : bgGray

  return (
    <main className="min-h-screen bg-gray-100 relative">
      <div className={bg}>
        <div className="w-full max-w-md">
          {!isMockTest && !verified ? (
            <div className="w-full flex items-center justify-center">
              <Turnstile
                siteKey={turnStileKey!}
                onSuccess={() => {
                  setVerified(true)
                }}
                onError={() => {
                  console.error('Turnstile verification failed')
                }}
                options={{
                  theme: 'light',
                  size: 'normal',
                }}
              />
            </div>
          ) : (
            <div className="relative z-10 ">
              <AppBar codeStatus={codeStatus} />
              <Content codeStatus={codeStatus} />
              <Footer codeStatus={codeStatus} />
            </div>
          )}
        </div>
      </div>
    </main>
  )
}

export default AshRedeemPage
