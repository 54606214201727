import 'reflect-metadata'
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'
import FailProcess from './section/failProcess'
import AuthVerification from './page/authVerification'
import ShareLink from './page/shareLink'
import PaymentPage from './page/payment'
import PaymentCompletePage from './page/paymentComplete'
import TopUpPackages from './page/topUpPackages'
import Layout from './component/Layout'
import Login from './page/login'
import ForgotPassword from './page/forgotPassword'
import PaymentQrPage from './page/paymentQr'
import PaymentFailedPage from './page/paymentFailed'
import { LeaderboardPage } from './page/campaign-leaderboard'
import AshRedeemPage from './page/ashRedeem'

const ProtectedRoute = () => {
  return <Layout isPublic={false} />
}

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<ProtectedRoute />}>
            {/* Topup flow auth protected route */}
            <Route path="/packages" element={<TopUpPackages />} />
          </Route>
          <Route element={<Layout />}>
            {/* Non auth protected route */}
            <Route path="/login" element={<Login />} />
          </Route>
          <Route element={<Layout />}>
            {/* Non auth protected route */}
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
          <Route path="/auth_verification" element={<AuthVerification />} />
          <Route path="/share_link" element={<ShareLink />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/campaign-leaderboard/:campaign_id" element={<LeaderboardPage />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/payment/complete" element={<PaymentCompletePage />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/payment/failed" element={<PaymentFailedPage />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/payment/qr" element={<PaymentQrPage />} />
          </Route>
          <Route path="*" element={<FailProcess mode="default" />} />
          <Route path="/redeem/:couponCode" element={<AshRedeemPage />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
