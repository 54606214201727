import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Loading from '../component/Loading'
import { LeaderBoardHeader } from '../component/LeaderBoardHeader'
import { LeaderBoardList } from '../component/LeaderBoardList'
import { useParams } from 'react-router-dom'
import { PopupDetail } from '../component/PopupDetail'

interface LeaderBoardData {
  leaderboardHeaderData: LeaderboardHeaderData
  numberTopSpenderReward: number
  numberTopSpenderRanking: number
  topSpenderArray: TopSpenderData[]
  announceConfigList: AnnounceConfig[]
  moreInfoDetails: string
}

export interface AnnounceConfig {
  announceDate: Date
  isShowType: boolean
  topSpenderDataType: TopSpenderDataType
}
export interface LeaderboardHeaderData {
  bannerFile: string
  redirectLink: string
  campaignName: string
  isShowProgressBar: boolean
  voteTargetValue: number
  voteCurrentValue: number
  descriptionAnnounce: string
}

export enum TopSpenderDataType {
  Coin = 'top_spender_paid',
  Vote = 'top_count_vote',
}

export interface TopSpenderData {
  display_name: string
  full_name: string
  profile_image: string
  total_spent: number
  vote_count: number
  rank: number
  date_created: string
}

export const LeaderboardPage = () => {
  const [isLoading, setLoading] = useState(true)
  const popupDetail = useRef<HTMLDivElement>(null)

  const leaderBoardData = useRef<LeaderBoardData>({
    leaderboardHeaderData: {
      bannerFile: '',
      redirectLink: '',
      campaignName: '',
      isShowProgressBar: false,
      voteTargetValue: 0,
      voteCurrentValue: 0,
      descriptionAnnounce: '',
    },
    numberTopSpenderReward: 0,
    numberTopSpenderRanking: 0,
    topSpenderArray: [],
    announceConfigList: [],
    moreInfoDetails: '',
  })

  const { campaign_id } = useParams()

  async function getTopSpender(limitUser: number) {
    try {
      const response = await axios.get(
        `${new URL(
          `/api/event/v1/admin/event/campaigns/${campaign_id}/top-spender?limit=${limitUser}`,
          `${process.env.REACT_APP_API_GATEWAY_URL}`,
        )}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': 'EN',
            'x-api-key': `${process.env.REACT_APP_API_KEY_LEADERBOARD}`,
          },
        },
      )

      return response.data
    } catch (e) {
      throw e
    }
  }

  function mapDataTopSpender(objectData: any) {
    const bannerItem = objectData?.data?.banner_items || []
    const nameTranslations = objectData?.data?.name_translations || []
    const dataMap = {
      leaderboardHeaderData: {
        bannerFile: bannerItem[0]?.file,
        redirectLink: bannerItem[0]?.url || bannerItem[0]?.campaign,
        campaignName: nameTranslations[0]?.name || 'Leaderboard Campaign',
        isShowProgressBar: objectData?.data?.show_progress_campaign_bar || false,
        voteTargetValue: Number(objectData?.data?.vote_target_value) || 0,
        voteCurrentValue: objectData?.data?.vote_current_value || 0,
        descriptionAnnounce: objectData?.data?.sentence_announce || '',
      },
      // map with default
      numberTopSpenderReward: Number(objectData?.data?.no_top_spender_reward) || 5,
      numberTopSpenderRanking: Number(objectData?.data?.no_top_spender_ranking) || 10,
      topSpenderArray: objectData?.data?.top_spender || [],

      announceConfigList:
        objectData?.data?.announce_datetime_top_spender?.map((x: any) => {
          return {
            announceDate: x.announce_datetime ? new Date(x.announce_datetime) : undefined,
            isShowType: x.show_type_top_spender,
            topSpenderDataType: x.type_show_web_top_spender || TopSpenderDataType.Vote,
          }
        }) || [],

      moreInfoDetails: objectData?.data?.moreinfo_web_top_spender || '',
    }
    return dataMap
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        leaderBoardData.current = mapDataTopSpender(await getTopSpender(0))

        const totalLimit =
          leaderBoardData.current.numberTopSpenderReward + leaderBoardData.current.numberTopSpenderRanking
        leaderBoardData.current = mapDataTopSpender(await getTopSpender(totalLimit))

        setLoading(false)
      } catch (e) {
        // FIXME: need design case error
        console.error(e)
      }
    }
    fetchData()
  }, [])

  if (isLoading)
    return (
      <div
        className="flex flex-col h-svh items-center 
                      justify-center touch-none bg-[#1594FF]"
      >
        <Loading />
      </div>
    )

  return (
    <div
      className="h-svh flex flex-col items-center justify-center 
                touch-none select-none bg-[#1594FF]"
    >
      <LeaderBoardHeader
        leaderboardHeaderData={leaderBoardData.current.leaderboardHeaderData}
        onOpenPopupDetail={() => {
          popupDetail.current?.style.setProperty('display', 'inline')
        }}
      />
      <LeaderBoardList
        topSpenderDataArray={leaderBoardData.current.topSpenderArray}
        numberTopSpenderReward={leaderBoardData.current.numberTopSpenderReward}
        numberTopSpenderRanking={leaderBoardData.current.numberTopSpenderRanking}
        announceConfigList={leaderBoardData.current.announceConfigList}
      />
      <div className={`absolute w-full h-full hidden`} ref={popupDetail}>
        {/* // TODO : change hard-code info detail text and handle for data from api (later) */}
        <PopupDetail
          campaignName={leaderBoardData.current.leaderboardHeaderData.campaignName}
          details={leaderBoardData.current.moreInfoDetails}
          onClosePopupDetail={() => {
            popupDetail.current?.style.setProperty('display', 'none')
          }}
        />
      </div>
    </div>
  )
}
